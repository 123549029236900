import { Show, useTranslation } from '@patrianna/core-components'
import { capitalize, formatCurrencyValue } from '@patrianna/shared-utils'
import { replacePrismicDynamicValues } from '@patrianna/shared-utils/prismic'
import { checkIfShowSpinInfo } from '@patrianna-ui-features/latest-wins'
import cx from 'classnames'

import { useAuth } from 'app/context/auth'
import RichText from 'components/RichText'

import classes from './WinContent.module.scss'
import type { WinContentProps } from './WinContent.types'

export function WinContent({ winner, amount, currency, spinAmount, spinCurrency, firstRowSlot }: WinContentProps) {
  const { isLoggedIn, isScEnabled } = useAuth()
  const t = useTranslation()

  const isScCurrency = currency === 'SC'
  const formattedWinner = capitalize(winner)
  const formattedWinAmount = formatCurrencyValue(amount, currency)
  const formattedSpinAmount = formatCurrencyValue(spinAmount, spinCurrency)
  const spinCurrencyText = spinCurrency === 'SC' ? t('common.sweepstakes_coins_sc') : t('common.gold_coin_gc')
  const shouldShowSpinInfo = checkIfShowSpinInfo({
    isLoggedIn,
    isScEnabled,
    isSpinScCurrency: spinCurrency === 'SC',
    isSpinAmountValid: Boolean(Number(spinAmount)),
  })

  return (
    <p
      className={cx(classes.root, {
        [classes.gc]: currency === 'GC',
        [classes.sc]: currency === 'SC',
      })}
    >
      <span className={classes.firstRow}>
        <span className={classes.winner}>
          {replacePrismicDynamicValues(t('latestWins.winner'), { winner: formattedWinner })}
        </span>
        {firstRowSlot && (
          <>
            <span>•</span>
            {firstRowSlot}
          </>
        )}
      </span>

      <span className={classes.amount}>
        <img
          src={`${process.env.IMG_ORIGIN}/latest-wins/${isScCurrency ? 'sc' : 'gc'}-currency.png`}
          alt={'Currency'}
          width={isScCurrency ? 28 : 19}
          height={isScCurrency ? 19 : 22}
        />
        <span>{formattedWinAmount}</span>
      </span>

      <Show when={shouldShowSpinInfo}>
        <RichText
          field={t('latestWins.spin')}
          components={{
            paragraph: ({ children }) => <span className={classes.spin}>{children}</span>,
            strong: () => (
              <span>
                {spinCurrencyText} {formattedSpinAmount}
              </span>
            ),
          }}
        />
      </Show>
    </p>
  )
}
