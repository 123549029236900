'use client'

import { RecentWinTicker } from 'features/latest-wins'
import { JackpotTickerContainer } from 'features/providers-jackpots'

import ProductTileModeBottomFrame from '../ProductTileModeFrame/ProductTileModeBottomFrame'

import type { TileBottomBarProps } from './TileBottomBar.types'

export default function TileBottomBar({ game }: TileBottomBarProps) {
  return (
    <>
      <JackpotTickerContainer game={game} />
      <RecentWinTicker productCode={game.code} />
      <ProductTileModeBottomFrame mode={game.mode} />
    </>
  )
}
