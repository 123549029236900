import { pick } from '@patrianna/shared-utils'
import type { HugeWinItemProps } from '@patrianna-ui-features/latest-wins'
import cx from 'classnames'

import { useRouter } from 'app/context/navigation'
import { getSlotStartUrlByGameTags } from 'utils/navigator/games'

import { ProductTile, WinContent, WinImage, WinTime, WinType } from './components'
import classes from './HugeWinItem.module.scss'

export function HugeWinItem({ product }: HugeWinItemProps) {
  const { push } = useRouter()

  const handleWinItemClick = () => {
    push(`${getSlotStartUrlByGameTags(product.tags)}/${product.route}`)
  }

  if (!product.bigWinInfo) {
    return null
  }

  const { currency, timestamp } = product.bigWinInfo
  const winContentProps = pick(product.bigWinInfo, ['winner', 'amount', 'currency', 'spinAmount', 'spinCurrency'])
  const winImageProps = pick(product.bigWinInfo, ['winType', 'currency'])
  const winTypeProps = pick(product.bigWinInfo, ['winType', 'currency'])

  return (
    <article className={classes.root} onClick={handleWinItemClick}>
      <section
        className={cx(classes.container, {
          [classes.gc]: currency === 'GC',
          [classes.sc]: currency === 'SC',
        })}
      >
        <ProductTile productCode={product.code} alt={product.title} />
        <div className={classes.winBlock}>
          <WinImage {...winImageProps} />
          <WinContent {...winContentProps} firstRowSlot={<WinTime timestamp={timestamp} />} />
        </div>
      </section>

      <footer className={classes.footer}>
        <WinType {...winTypeProps} />
      </footer>
    </article>
  )
}
