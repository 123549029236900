import cx from 'classnames'
import classes from './ArrowIcon.module.scss'

type Props = {
  rotated?: boolean
  width?: number
  height?: number
  shevronShape?: boolean
}

export const ArrowIcon = ({ rotated = false, width = 48, height = 32, shevronShape }: Props) => (
  <img
    className={cx(classes.arrowIcon, { [classes.rotated]: rotated })}
    width={width}
    height={height}
    src={`${process.env.IMG_ORIGIN}${shevronShape ? '/icons/shevron-left.svg?v1' : '/menu/arrow_scroll.png'}`}
    alt='no ads icon'
  />
)
