import { MC_JACKPOT_WIN_TYPES } from '@patrianna-ui-features/latest-wins'
import cx from 'classnames'

import classes from './WinImage.module.scss'
import type { WinImageProps } from './WinImage.types'

const IMG_BASE_URL = `${process.env.IMG_ORIGIN}/latest-wins`

const imageConfigs = {
  gcWin: {
    src: `${IMG_BASE_URL}/gc-win.png`,
    className: classes.gcWin,
  },
  scWin: {
    src: `${IMG_BASE_URL}/sc-win.png`,
    className: classes.scWin,
  },
  gcJackpotWin: {
    src: `${IMG_BASE_URL}/gc-wheel.png`,
    className: classes.gcJackpotWin,
  },
  scJackpotWin: {
    src: `${IMG_BASE_URL}/sc-wheel.png`,
    className: classes.scJackpotWin,
  },
}

function getWinConfigKey({ winType, currency }: WinImageProps) {
  if (MC_JACKPOT_WIN_TYPES.includes(winType)) {
    return currency === 'SC' ? 'scJackpotWin' : 'gcJackpotWin'
  }

  return currency === 'SC' ? 'scWin' : 'gcWin'
}

export function WinImage({ winType, currency }: WinImageProps) {
  const winKey = getWinConfigKey({ currency, winType })
  const image = imageConfigs[winKey]

  if (!image) {
    return null
  }

  return <img src={image.src} alt='Win' className={cx(classes.root, image.className)} />
}
