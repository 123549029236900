'use client'

import { Link } from 'components/Link'
import cx from 'classnames'
import type { GameProductShort, SwimlaneCategoryStyle } from '@patrianna/shared-patrianna-types/store/GameModule'
import { useAppDispatch } from 'store/hooks'
import images from 'temp/images.json'
import { closeAllDialogs } from 'store/modules/dialog/actions'
import { getSlotStartUrlByGameTags } from 'utils/navigator/games'
import classes from './styles.module.scss'
import { Picture } from '../Picture'
import { ImageType, type PictureProps } from '../Picture/Picture.types'
import FavoriteButton from 'containers/FavoriteButton'
import { Show } from '@patrianna/core-components'
import { isDefaultGameProductTags } from 'components/ProductTileTags/utils'
import TileBottomBar from 'components/ProductTile/components/ProductTileBottomBar'
import TilesTopBar from 'components/ProductTile/components/ProductTilesTopBar'

export type Props = {
  game: GameProductShort
  className?: string
  moreRows?: boolean
  handleTileClick?: (gameCode: string) => void
  isSearch?: boolean
  swimlaneStyle?: SwimlaneCategoryStyle
  isAsLink?: boolean
}

function ProductTile({ game, className, handleTileClick, isSearch, swimlaneStyle, isAsLink = true }: Props) {
  const dispatch = useAppDispatch()
  const url = `${getSlotStartUrlByGameTags(game.tags)}/${game.route}`
  const isDefaultTags = isDefaultGameProductTags(game.tags)
  const shouldShowFavorite = isDefaultTags.length < 2

  const pictureProps: PictureProps = {
    src: `${images.bucket}/tiles-v3/${game.code}/source.png`,
    alt: game.title,
    className: classes.image,
    type: isSearch ? ImageType.SEARCH : ImageType.TILE,
    loading: 'lazy',
  }

  return isAsLink ? (
    <Link
      href={url}
      className={cx(
        classes.root,
        classes.rootVisible,
        className && {
          [className]: className,
        }
      )}
      onClick={() => {
        handleTileClick?.(game.route)
        dispatch(closeAllDialogs())
      }}
    >
      <div className={classes.imageContainer} data-test={`game-tile-${game.code}`}>
        <TilesTopBar game={game} swimlaneStyle={swimlaneStyle} />
        <Picture {...pictureProps} />
        <Show when={shouldShowFavorite}>
          <div className={classes.favouriteBlock}>
            <FavoriteButton gameProduct={game} />
          </div>
        </Show>
        <TileBottomBar game={game} />
      </div>
    </Link>
  ) : (
    <div
      className={cx(
        classes.root,
        classes.rootVisible,
        className && {
          [className]: className,
        }
      )}
      onClick={() => {
        handleTileClick?.(game.route)
        dispatch(closeAllDialogs())
      }}
    >
      <div className={classes.imageContainer} data-test={`game-tile-${game.code}`}>
        <TilesTopBar game={game} swimlaneStyle={swimlaneStyle} />
        <Picture {...pictureProps} />
        <Show when={shouldShowFavorite}>
          <div className={classes.favouriteBlock}>
            <FavoriteButton gameProduct={game} />
          </div>
        </Show>
        <TileBottomBar game={game} />
      </div>
    </div>
  )
}

export default ProductTile
