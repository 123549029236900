import cx from 'classnames'
import { IconButton } from '@patrianna/core-components'
import { Close } from '@patrianna-icons/material-icons'
import { useAppDispatch } from 'store/hooks'
import { closeSnackbar } from 'store/modules/snackbar/actions'
import MessageContent from 'containers/GlobalSnackbar/MessageContent'
import classes from './ConfirmationMessageSnackbar.module.scss'
import { useTranslation } from '@patrianna/core-components'

type ConfirmationMessageContentProps = {
  message: string
}

export default function ConfirmationMessageContent(props: ConfirmationMessageContentProps) {
  const { message } = props
  const dispatch = useAppDispatch()
  const t = useTranslation()

  const handleOnClick = () => {
    dispatch(closeSnackbar())
  }

  return (
    <div className={cx(classes.root, 'mt-modal')}>
      <IconButton onClick={handleOnClick} className={cx(classes.close)} data-test='close-modal-button'>
        <Close />
      </IconButton>
      <div className={cx(classes.message, 'text-bigger')}>
        <MessageContent message={t(message)} />
      </div>
    </div>
  )
}
