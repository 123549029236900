import {
  latestWinsConfig,
  LatestWinsProvider,
  HugeWins as HugeWinsDefault,
  type HugeWinsProps,
  RecentWinTicker as RecentWinTickerDefault,
  type RecentWinTickerProps,
} from '@patrianna-ui-features/latest-wins'

import CarouselHeader from 'components/GameRowHeader'
import CarouselScroller from 'containers/HorizontalScroll'

import { useLatestWinsProviderProps } from './hooks'
import { HugeWinItem } from './components'
import classes from './styles.module.scss'

// TODO: Improve config after feature improvements.
// RichText, getProductTilePath & currency icons should be optional if hugeWins.WinItemComponent was provided.
latestWinsConfig.config = {
  feature: {
    hugeWins: {
      WinItemComponent: HugeWinItem,
    },
    imageManager: {
      gcCurrencyIcon: {
        src: '',
      },
      scCurrencyIcon: {
        src: '',
      },
      gcMcJackpotBadge: {
        src: `${process.env.IMG_ORIGIN}/jackpots/jp.png`,
        width: 60,
        height: 20,
      },
      scMcJackpotBadge: {
        src: `${process.env.IMG_ORIGIN}/jackpots/jp-sc2.png`,
        width: 60,
        height: 20,
      },
    },
  },
  customClassNames: {
    ticker: {
      root: classes.ticker,
      tag: classes.tag,
    },
  },
  helpers: {
    getProductTilePath: () => '',
  },
  components: {
    CarouselHeader,
    CarouselScroller,
    RichText: () => null,
  },
}

export function HugeWins(props: HugeWinsProps) {
  const providerProps = useLatestWinsProviderProps()

  return (
    <LatestWinsProvider {...providerProps}>
      <HugeWinsDefault {...props} />
    </LatestWinsProvider>
  )
}

export function RecentWinTicker(props: RecentWinTickerProps) {
  const providerProps = useLatestWinsProviderProps()

  return (
    <LatestWinsProvider {...providerProps}>
      <RecentWinTickerDefault {...props} />
    </LatestWinsProvider>
  )
}
