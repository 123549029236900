import classes from './ProductTile.module.scss'
import type { ProductTileProps } from './ProductTile.types'

export function ProductTile({ productCode, alt }: ProductTileProps) {
  return (
    <img
      src={`${process.env.IMG_ORIGIN}/../tiles-v3/${productCode}/source.png`}
      alt={alt}
      width={98}
      height={131}
      className={classes.root}
    />
  )
}
