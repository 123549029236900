import { useTranslation } from '@patrianna/core-components'
import { replacePrismicDynamicValues } from '@patrianna/shared-utils/prismic'
import { differenceInSeconds, formatDistanceToNowStrict } from 'date-fns'

import { ONE_MINUTE_IN_SECONDS } from './WinTime.constants'
import classes from './WinTime.module.scss'
import type { WinTimeProps } from './WinTime.types'

export function WinTime({ timestamp }: WinTimeProps) {
  const t = useTranslation()

  const diffInSeconds = differenceInSeconds(new Date().getTime(), timestamp)
  const distanceToNow = formatDistanceToNowStrict(timestamp)

  const timeSinceWin =
    diffInSeconds < ONE_MINUTE_IN_SECONDS
      ? t('latestWins.now')
      : replacePrismicDynamicValues(t('latestWins.win_time'), { distanceToNow })

  return <time className={classes.root}>{timeSinceWin}</time>
}
