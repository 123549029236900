import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'

import { useAuth } from 'app/context/auth'
import { useRouter } from 'app/context/navigation'
import { getSlotStartUrlByGameTags } from 'utils/navigator/games'

// TODO: handleWinItemClick should be optional if hugeWins.WinItemComponent was provided.
export function useLatestWinsProviderProps() {
  const { isLoggedIn, isScEnabled } = useAuth()
  const { push } = useRouter()

  const handleWinItemClick = ({ tags, route }: Pick<GameProductShort, 'tags' | 'route'>) => {
    push(`${getSlotStartUrlByGameTags(tags)}/${route}`)
  }

  return { isLoggedIn, isScEnabled, handleWinItemClick }
}
