import { useTranslation } from '@patrianna/core-components'
import { replacePrismicDynamicValues } from '@patrianna/shared-utils/prismic'
import cx from 'classnames'

import classes from './WinType.module.scss'
import type { WinTypeProps } from './WinType.types'

export function WinType({ winType, currency }: WinTypeProps) {
  const t = useTranslation()

  let title = ''
  switch (winType) {
    case 'jackpot_mini':
      title = replacePrismicDynamicValues(t('latestWins.jackpot_mini'), { currency })
      break

    case 'jackpot_minor':
      title = replacePrismicDynamicValues(t('latestWins.jackpot_minor'), { currency })
      break

    case 'jackpot_major':
      title = replacePrismicDynamicValues(t('latestWins.jackpot_major'), { currency })
      break

    case 'jackpot_grand':
      title = replacePrismicDynamicValues(t('latestWins.jackpot_grand'), { currency })
      break

    default:
      title = replacePrismicDynamicValues(t('latestWins.big_win'), { currency })
      break
  }

  return (
    <div
      className={cx(classes.root, {
        [classes.gc]: currency === 'GC',
        [classes.sc]: currency === 'SC',
      })}
    >
      <span className={classes.text}>{title}</span>
    </div>
  )
}
